<template>
  <div v-if="taskStatus === 'success'" class="d-flex flex-1 flex-scroll-height">
    <div class="d-flex flex-column ant-border-right" style="width: 50%">
      <div
        class="d-flex px-5 py-2 align-center ant-glass-background radius-0 ant-border-bottom"
      >
        <div style="font-size: 14px">
          <v-tooltip v-if="task.parent" bottom>
            <template #activator="{ on }">
              <v-icon class="ant-icon" @click="navigateToParent" v-on="on">
                mdi-chevron-left
              </v-icon>
            </template>
            <span>To parent</span>
          </v-tooltip>
          Task created
          <span class="font-italic">{{
            calculateCreationTime(task.created_at)
          }}</span>
          ago by
          <span class="font-weight-bold">
            {{ task.created_by.firstname }} {{ task.created_by.lastname }}
          </span>
        </div>
        <v-spacer />
        <v-btn
          v-if="task.status === 'open'"
          class="mr-2"
          color="warning"
          elevation="0"
          outlined
          small
          :disabled="task.task_type?.type === 'scriptserver'"
          tile
          @click="cancelTask(task)"
          >cancel
        </v-btn>
        <v-btn
          :color="task.status === 'open' ? 'success' : ''"
          :disabled="
            closeOpenStatus === 'loading' ||
            task.children.length > 0 ||
            task.task_type?.type === 'scriptserver'
          "
          :loading="closeOpenStatus === 'loading'"
          elevation="0"
          outlined
          small
          tile
          @click="closeOrOpenTask(task)"
          >{{ task.status === 'open' ? 'complete' : 'reopen' }}
        </v-btn>
      </div>
      <div class="flex-1 d-flex flex-column flex-scroll-height overflow-y-auto">
        <div class="pa-5 flex-1">
          <div
            :class="{ 'font-weight-bold': !editMode }"
            class="d-flex mb-2 align-center"
            style="font-size: 18px"
          >
            <div style="font-size: 16px; color: gray; font-weight: 400">
              #{{ task.number }}
            </div>
            <v-tooltip v-if="!editMode" bottom>
              <template #activator="{ on }">
                <v-icon
                  class="mx-2"
                  :color="getPriorityColor(task.priority)"
                  v-on="on"
                  >{{ getPriorityIcon(task.priority) }}
                </v-icon>
              </template>
              <span>{{ task.priority }}</span>
            </v-tooltip>
            <v-select
              v-if="editMode"
              v-model="editTask.priority"
              :items="['low', 'normal', 'high', 'urgent']"
              dense
              filled
              hide-details
              placeholder="normal"
              style="flex: 0"
            >
              <template #selection="{ item, index }">
                <v-icon :color="getPriorityColor(item)" class="mr-2"
                  >{{ getPriorityIcon(item) }}
                </v-icon>
                {{ item }}
              </template>
              <template #item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon :color="getPriorityColor(item)"
                      >{{ getPriorityIcon(item) }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
            <div
              v-if="!editMode"
              class="d-flex align-center"
              style="width: 100%"
            >
              <v-tooltip v-if="task.task_type" bottom>
                <template #activator="{ on }">
                  <v-chip class="mr-1 font-weight-regular" small v-on="on"
                    >{{ task.task_type.type }}
                  </v-chip>
                </template>
                <span>task type</span>
              </v-tooltip>
              {{ task.title }}
              <v-spacer />
              <v-progress-circular
                v-show="reloading"
                size="25"
                color="primary"
                indeterminate
              />
            </div>
            <v-text-field
              v-else
              v-model="editTask.title"
              dense
              filled
              hide-details
              placeholder="title"
            />
            <v-spacer v-if="!editMode" />
            <v-btn
              v-if="task.status === 'open' && !editMode"
              icon
              small
              tile
              @click="activateEditMode"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="task.status === 'open' && !editMode"
              icon
              small
              tile
              @click="taskDeleteDialog = true"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
            <delete-tasks-dialog
              :is-shown="taskDeleteDialog"
              :tasks="[task]"
              @on-delete="onDeleteDialogClose"
              @close-dialog="taskDeleteDialog = false"
            />
            <v-btn
              v-if="editMode"
              class="mx-2"
              icon
              small
              tile
              @click="deactivateEditMode"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              v-if="editMode"
              class="mx-2"
              icon
              small
              tile
              @click="updateTask"
            >
              <v-icon small>mdi-content-save-outline</v-icon>
            </v-btn>
          </div>
          <div v-if="!editMode" class="mb-5">
            {{ task.description }}
          </div>
          <div
            v-if="task.task_type && task.task_type.executable && !editMode"
            class="d-flex align-center mb-5"
          >
            <v-btn
              :loading="executing"
              color="primary"
              outlined
              small
              tile
              @click="executeTask"
              >Execute
            </v-btn>
          </div>
          <v-textarea
            v-if="editMode"
            v-model="editTask.description"
            :rules="[rules.maxLength]"
            filled
            placeholder="Please add a task description here"
          />
          <task-label
            :editing="editMode"
            :labels="task?.labels"
            :project-id="task?.task_project?.id"
            class="my-2"
            @on-change="onLabelChange"
          />
          <div class="d-flex mb-5">
            <div
              v-if="task.task_project || editMode"
              class="d-flex flex-column flex-1"
            >
              <v-subheader
                class="font-weight-bold pa-0"
                style="font-size: 11px; height: auto"
              >
                Project
              </v-subheader>
              <v-chip
                v-if="!editMode"
                :ripple="false"
                color="white"
                label
                @click="navigateToProject()"
              >
                {{ task.task_project.name }}
              </v-chip>
              <v-combobox
                v-else
                v-model="editTask.project"
                :items="projects"
                dense
                disabled
                filled
                hide-details
                item-text="name"
                placeholder="project"
                return-object
              />
            </div>
            <div
              v-if="task.sbscode || editMode"
              class="d-flex flex-column ml-2"
            >
              <v-subheader
                class="font-weight-bold pa-0"
                style="font-size: 11px; height: auto"
              >
                Sbscode
              </v-subheader>
              <v-chip v-if="!editMode" :ripple="false" color="white" label>
                {{
                  task.sbscode.label ? task.sbscode.label : task.sbscode.code
                }}
              </v-chip>
              <v-combobox
                v-else
                v-model="editTask.sbscode"
                :filter="filterSbsObjects"
                :items="sbsRecords"
                :loading="sbsLoading"
                clearable
                dense
                filled
                hide-details
                item-text="code"
                placeholder="Sbscode"
              >
                <template #item="{ item }">
                  <div class="d-flex align-center">
                    {{ item.label ? item.label : '-' }}
                    <v-divider class="mx-2" vertical />
                    <div class="font-italic fs-12 d-flex align-center">
                      {{ item.code }}
                    </div>
                  </div>
                </template>
              </v-combobox>
            </div>
          </div>

          <div class="my-5 d-flex flex-column">
            <v-subheader
              class="font-weight-bold pa-0"
              style="font-size: 11px; height: auto"
            >
              Executed by
            </v-subheader>
            <div v-if="editMode">
              <v-icon v-if="!editTask.assigned_to" class="ant-icon" small>
                mdi-plus
              </v-icon>
              <v-combobox
                v-model="editTask.assigned_to"
                :items="users"
                dense
                filled
                hide-details
                item-text="email"
                item-value="id"
                placeholder="email"
                return-object
                single-line
              />
            </div>

            <div v-else-if="task.assigned_to" class="d-flex align-center">
              <v-avatar class="mr-2 white--text" color="primary" size="30">
                {{
                  `${task.assigned_to.firstname.charAt(
                    0
                  )}${task.assigned_to.lastname.charAt(0)}`
                }}
              </v-avatar>
              <div class="d-flex align-center" style="font-size: 12px">
                {{ task.assigned_to.email }}
              </div>
            </div>
          </div>
          <div class="d-flex mb-5">
            <div class="d-flex flex-column flex-1" style="font-size: 14px">
              <v-subheader
                class="font-weight-bold pa-0"
                style="font-size: 11px; height: auto"
              >
                Due
              </v-subheader>
              <div v-if="!editMode" class="d-flex flex-column">
                <span>{{ formatDate(task.due) }}</span>
                <span class="font-italic fs-12">{{
                  formatTime(task.due)
                }}</span>
              </div>
              <v-text-field
                v-else
                v-model="editTask.due"
                :placeholder="today"
                clearable
                dense
                filled
                hide-details
                prepend-inner-icon="mdi-calendar"
              />
            </div>
            <div class="d-flex flex-column flex-1 mx-2" style="font-size: 14px">
              <v-subheader
                class="font-weight-bold pa-0"
                style="font-size: 11px; height: auto"
              >
                start date
              </v-subheader>
              <div v-if="!editMode" class="d-flex flex-column">
                <span>{{ formatDate(task.planned_start) }}</span>
                <span class="font-italic fs-12">{{
                  formatTime(task.planned_start)
                }}</span>
              </div>
              <v-text-field
                v-else
                v-model="editTask.planned_start"
                :placeholder="today"
                clearable
                dense
                filled
                hide-details
                prepend-inner-icon="mdi-calendar"
              />
            </div>
            <div class="d-flex flex-column flex-1" style="font-size: 14px">
              <v-subheader
                class="font-weight-bold pa-0"
                style="font-size: 11px; height: auto"
              >
                end date
              </v-subheader>
              <div v-if="!editMode" class="d-flex flex-column">
                <span>{{ formatDate(task.planned_end) }}</span>
                <span class="font-italic fs-12">{{
                  formatTime(task.planned_end)
                }}</span>
              </div>
              <v-text-field
                v-else
                v-model="editTask.planned_end"
                :placeholder="today"
                clearable
                dense
                filled
                hide-details
                prepend-inner-icon="mdi-calendar"
              />
            </div>
          </div>
          <div v-if="!editMode" class="d-flex flex-column mb-2">
            <v-file-input
              id="taskAppendixInput"
              class="pa-0 ma-0"
              hide-input
              multiple
              style="height: 0; opacity: 0"
              @change="uploadTaskAppendixes"
            />
            <v-subheader
              class="font-weight-bold pa-0"
              style="font-size: 11px; height: auto"
            >
              Appendixes
            </v-subheader>
            <div class="d-flex flex-scroll-width overflow-x-auto">
              <v-btn
                v-if="task.status === 'open'"
                :style="{
                  width: task.appendixes.length === 0 ? '100%' : '80px',
                }"
                class="task-appendix-input d-flex align-center justify-center"
                elevation="0"
                style="height: 60px; width: 100%"
                @click="openFileInput"
              >
                <v-icon dense>mdi-file-upload</v-icon>
                <div v-if="task.appendixes.length === 0" class="ml-2">
                  <span class="text-decoration-underline mr-1">Click here</span>
                  to upload files
                </div>
              </v-btn>

              <v-tooltip
                v-for="appendix in task.appendixes"
                :key="appendix.id"
                bottom
              >
                <template #activator="{ on }">
                  <div
                    class="task-appendix ant-glass-background mx-1 d-flex align-center justify-center pa-2"
                    style="height: 60px; width: 80px"
                    @click="downloadAppendix(appendix.task, appendix.id)"
                    v-on="on"
                  >
                    <v-icon
                      class="ant-icon"
                      small
                      style="position: absolute; right: 5px; top: 5px"
                      @click.stop="setupAppendixDelete(appendix)"
                      >mdi-delete
                    </v-icon>
                    <div class="d-flex flex-column full-width">
                      <v-icon small>mdi-file</v-icon>
                      <div
                        class="text-no-wrap overflow-hidden"
                        style="text-overflow: ellipsis"
                      >
                        {{ appendix.name }}
                      </div>
                    </div>
                  </div>
                </template>
                <span>{{ appendix.name }}.{{ appendix.extension }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div class="ant-glass-background radius-0 ant-border-top flex-1">
          <div class="d-flex flex-column flex-scroll-height">
            <div class="pa-5 d-flex flex-column ant-border-bottom">
              <div class="d-flex">
                <v-subheader
                  class="font-weight-bold pa-0"
                  style="font-size: 11px; height: auto"
                >
                  Checklist
                </v-subheader>
                <v-spacer />
                <transition mode="out-in" name="simple-fade">
                  <v-text-field
                    v-if="addCheckToggle"
                    v-model="checkTitle"
                    autofocus
                    class="ma-0"
                    clearable
                    dense
                    hide-details
                    label="Title"
                    single-line
                    @click:clear="
                      addCheckToggle = false;
                      checkTitle = null;
                    "
                    @keydown.enter="createTaskCheck"
                  ></v-text-field>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon
                        :style="{ opacity: task.status === 'open' ? 1 : 0 }"
                        class="ant-icon"
                        dense
                        @click="addCheckToggle = true"
                        v-on="on"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>Add checklist item</span>
                  </v-tooltip>
                </transition>
              </div>

              <div
                v-if="task.checks.length > 0"
                class="overflow-y-auto flex-1 pb-10"
              >
                <div
                  v-for="check in task.checks"
                  :key="check.id"
                  class="d-flex align-center mb-1 ant-border-bottom px-2 task-check-item"
                >
                  <v-checkbox
                    v-model="check.completed"
                    :disabled="task.status === 'closed'"
                    :ripple="false"
                    @click="toggleTaskCheck(check)"
                  />
                  <v-edit-dialog
                    :return-value.sync="check.title"
                    @save="updateTaskCheckTitle(check)"
                  >
                    {{ check.title }}
                    <template #input>
                      <v-text-field
                        v-model="check.title"
                        counter
                        label="Title"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <v-spacer />
                  <v-icon
                    v-if="task.status === 'open'"
                    class="task-check-delete-btn ant-icon"
                    small
                    @click="deleteTaskCheck(check)"
                    >mdi-delete
                  </v-icon>
                </div>
              </div>
              <div v-else class="font-italic" style="font-size: 12px">
                This task has no checklist items
              </div>
            </div>
            <div class="pa-5 d-flex flex-column overflow-hidden flex-1">
              <div class="d-flex">
                <v-subheader
                  class="font-weight-bold pa-0"
                  style="font-size: 11px; height: auto"
                >
                  Subtasks
                </v-subheader>
                <v-spacer />
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      :style="{ opacity: task.status === 'open' ? 1 : 0 }"
                      class="ant-icon"
                      dense
                      @click="$refs['add-child-task-dialog'].displayed = true"
                      v-on="on"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>Add child task</span>
                </v-tooltip>
                <v-menu
                  v-if="task.children.length"
                  v-model="menus.subtask"
                  attach="#menu-active"
                  close-on-click
                  left
                  top
                >
                  <template #activator="{ on, attrs }">
                    <div
                      id="menu-active"
                      class="d-flex align-center ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="ant-icon" dense>mdi-dots-vertical</v-icon>
                    </div>
                  </template>
                  <v-list dense>
                    <v-list-item
                      :disabled="!selectedSubtasks.length"
                      @click.stop="subtasksDeleteDialog = true"
                    >
                      <v-list-item-icon style="margin-right: 10px">
                        <v-icon dense> mdi-trash-can</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t('system.tasks.deleteSelectedTasks') }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click.stop="handleSubtasksSelection">
                      <v-list-item-icon style="margin-right: 10px">
                        <v-icon dense> {{ selectionMenuIcon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ selectionMenuText }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <add-task-dialog
                  ref="add-child-task-dialog"
                  :parent="task.id"
                  :project="task.task_project"
                />
                <delete-tasks-dialog
                  :initial-delete-method="DELETE_METHOD_WITH_SUBTASKS()"
                  :is-shown="subtasksDeleteDialog"
                  :tasks="selectedSubtasks"
                  @close-dialog="onSubtasksDeleteDialogClose"
                  @on-delete="onSubtasksDelete"
                />
              </div>
              <div
                v-if="task.children.length > 0"
                class="overflow-y-auto flex-1 pb-10"
              >
                <task-v2
                  v-for="task in task.children"
                  :key="task.id"
                  :selected="checkSelected(task.id)"
                  :task="task"
                  can-select
                  @on-select="onSubtaskSelect"
                />
              </div>
              <div v-else class="font-italic" style="font-size: 12px">
                This task has no subtasks
              </div>
            </div>
          </div>
        </div>

        <delete-dialog
          :dialog="appendixDeleteDialog"
          :title="
            appendixDeleteItem
              ? `Are you sure you want to delete ${appendixDeleteItem.name}.${appendixDeleteItem.extension}`
              : ''
          "
          @closeDialog="
            appendixDeleteItem = null;
            appendixDeleteDialog = false;
          "
          @deleteAction="removeTaskAppendix"
        />
      </div>
    </div>
    <div class="d-flex flex-column pa-5 flex-1 ant-glass-background radius-0">
      <div
        v-if="parentTree.length > 1"
        class="d-flex flex-column mb-5"
        style="max-height: 150px"
      >
        <v-subheader class="font-weight-bold pa-0" style="height: auto"
          >Parents
        </v-subheader>
        <div
          v-for="(parent, index) in parentTree"
          :key="parent.id"
          :style="{ 'margin-left': `${25 * index}px` }"
          class="d-flex"
        >
          <v-icon
            v-if="index > 0"
            :color="task.id === parent.id ? 'primary' : ''"
            dense
          >
            mdi-subdirectory-arrow-right
          </v-icon>
          <task-v2 :task="parent" class="flex-1" />
        </div>
      </div>
      <task-activities :task="task" />
    </div>
  </div>
  <div v-else class="d-flex align-center justify-center full-width full-height">
    <ant-loading />
  </div>
</template>

<script>
import AntLoading from '@/components/AntLoading';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';
import FileHandler from '@/services/file-handler';
import { downloadTaskAppendix, executeTask } from '@/services/api/task.api';
import DeleteDialog from '@/components/DeleteDialog';
import AddTaskDialog from '@/components/Tasks/AddTaskDialog';
import TaskV2 from '@/components/Tasks/TaskV2';
import TaskHelper from '@/services/task-helper';
import {
  getProjectMembersWithTaskPermissions,
  getProjects,
} from '@/services/api/projects.api';
import { getSbsRecords } from '@/services/api/sbs.api';
import DeleteTasksDialog from '@/components/Tasks/DeleteTasksDialog.vue';
import TaskActivities from '@/components/Tasks/TaskActivities.vue';
import TaskLabel from '@/components/Tasks/TaskLabel.vue';
import { isEqual } from 'lodash';
import { DELETE_METHOD_WITH_SUBTASKS } from '@/components/Tasks/task';

export default {
  name: 'TasksDetail',
  components: {
    TaskActivities,
    DeleteTasksDialog,
    TaskV2,
    AddTaskDialog,
    DeleteDialog,
    AntLoading,
    TaskLabel,
  },
  data: () => {
    return {
      closeOpenStatus: '',
      appendixDeleteDialog: false,
      appendixDeleteItem: null,
      taskDeleteDialog: false,
      addCheckToggle: false,
      checkTitle: '',
      editMode: false,
      editTask: {},
      projects: [],
      users: [],
      today: moment().format('YYYY-MM-DD' + ''),
      menus: {
        start_date: false,
        end_date: false,
        due_date: false,
        label: false,
        subtask: false,
      },
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          value ? value.length < 250 || 'Max 250 characters' : true,
      },
      addedLabels: [],
      executing: false,
      sbsLoading: false,
      sbsRecords: [],
      selectedSubtasks: [],
      subtasksDeleteDialog: false,
      fetchInternal: null,
      reloading: false,
    };
  },
  computed: {
    ...mapGetters([
      'task',
      'taskStatus',
      'authenticatedUser',
      'selectedLicense',
    ]),

    parentTree() {
      return this.createParentTree([], this.task).reverse();
    },

    planning() {
      let tmp = [];
      tmp.push(this.task);
      return tmp;
    },

    selectionMenuText() {
      return this.selectedSubtasks.length
        ? 'Deselect all tasks'
        : 'Select all tasks';
    },
    selectionMenuIcon() {
      return this.selectedSubtasks.length
        ? 'mdi-playlist-remove'
        : 'mdi-playlist-check';
    },
  },
  watch: {
    '$route.params.taskId': {
      immediate: true,
      handler(value) {
        if (value) {
          this.$store.dispatch('fetchTask', value);
          this.executing = false;
          this.menus = {
            start_date: false,
            end_date: false,
            due_date: false,
            label: false,
          };
        }
      },
    },
    async editMode(value) {
      if (value) {
        if (this.task.task_project) {
          await this.fetchSbsRecords(this.task.task_project);
        }
      }
    },
  },
  mounted() {
    const taskId = this.$route.params.taskId;
    if (taskId) {
      let firstReload = true;

      this.$store.dispatch('fetchTask', taskId);

      this.fetchInterval = setInterval(async () => {
        this.reloading = true;

        if (firstReload) {
          this.$store.commit('showNotification', {
            content: 'Task data is being reloaded in the background.',
            color: 'info',
          });
          firstReload = false;
        }

        await this.$store.dispatch('fetchTask', taskId);
        this.reloading = false;
      }, 7000);
    }
  },
  destroyed(to, from, next) {
    this.$store.commit('task_clear');
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
      this.fetchInterval = null;
    }
  },
  methods: {
    DELETE_METHOD_WITH_SUBTASKS() {
      return DELETE_METHOD_WITH_SUBTASKS;
    },
    navigateToProject() {
      this.$router.push({
        name: 'Project',
        params: { slug: this.task.task_project.slug },
      });
    },
    filterSbsObjects(item, queryText, itemText) {
      return (
        item?.label.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item?.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    async fetchSbsRecords(project) {
      this.sbsLoading = true;
      this.sbsRecords = await getSbsRecords(project.id, {});
      this.sbsLoading = false;
    },
    createParentTree(tree, task) {
      tree.push(task);
      if (task.parents) {
        return this.createParentTree(tree, task.parents);
      }
      return tree;
    },
    onDeleteDialogClose() {
      this.taskDeleteDialog = false;
      this.$router.push({ name: 'tasks-list' });
    },
    onLabelChange(labels) {
      this.editTask.labels = labels.map((l) => l.id);
    },
    async activateEditMode() {
      this.projects = await getProjects({
        'filter[license]': this.selectedLicense.id,
      });
      if (this.task.task_project) {
        this.users = await getProjectMembersWithTaskPermissions(
          this.task.task_project.id
        );
      }
      this.editMode = true;
      Object.assign(this.editTask, this.task);
      this.editTask.project = this.task.task_project;
    },
    updateTask() {
      let data = {
        title:
          this.editTask.title !== this.task.title ? this.editTask.title : null,
        description:
          this.editTask.description !== this.task.description
            ? this.editTask.description
            : null,
        priority:
          this.editTask.priority !== this.task.priority
            ? this.editTask.priority
            : null,
        planned_start:
          this.editTask.planned_start !== this.task.planned_start
            ? this.editTask.planned_start
            : null,
        planned_end:
          this.editTask.planned_end !== this.task.planned_end
            ? this.editTask.planned_end
            : null,
        due: this.editTask.due !== this.task.due ? this.editTask.due : null,
        project: this.editTask.project
          ? this.editTask.project.id !==
            (this.task.task_project ? this.task.task_project.id : '-1')
            ? this.editTask.project.id
            : null
          : null,
        assigned_to: this.editTask.assigned_to
          ? this.editTask.assigned_to.id !== this.task.assigned_to.id
            ? this.editTask.assigned_to.id
            : null
          : null,
        sbscode: this.editTask.sbscode
          ? this.editTask?.sbscode?.id !== this.task?.sbscode?.id
            ? this.editTask.sbscode.code
            : null
          : null,
        labels: this.editTask.labels
          ? !isEqual(this.editTask.labels, this.task.labels)
            ? this.editTask.labels
            : null
          : null,
      };

      let body = Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v != null)
      );

      this.$store
        .dispatch('updateTask', { taskId: this.task.id, body })
        .then(() => {
          this.deactivateEditMode();
        });
    },
    deactivateEditMode() {
      this.editMode = false;
      this.editTask = {};
    },
    navigateToParent() {
      this.$router.push({
        name: 'tasks-detail',
        params: { taskId: this.task.parent },
      });
    },
    calculateCreationTime(time) {
      let duration = moment.duration(moment().diff(moment(time)));
      if (duration.years() > 1) {
        return `${duration.years()} years`;
      }
      if (duration.months() > 1) {
        return `${duration.months()} months`;
      }
      if (duration.weeks() > 1) {
        return `${duration.weeks()} weeks`;
      }
      if (duration.days() > 1) {
        return `${duration.days()} days`;
      }
      if (duration.hours() > 1) {
        return `${duration.hours()} hours`;
      }
      if (duration.minutes() > 1) {
        return `${duration.minutes()} mins`;
      }
    },
    async cancelTask() {
      await this.$store.dispatch('cancelTask', this.task.id);
    },
    async closeOrOpenTask(task) {
      this.closeOpenStatus = 'loading';
      if (task.status === 'open') {
        await this.$store.dispatch('closeTask', this.task.id).catch(() => {
          this.closeOpenStatus = 'error';
        });
      } else {
        await this.$store.dispatch('reopenTask', this.task.id).catch(() => {
          this.closeOpenStatus = 'error';
        });
      }
      this.closeOpenStatus = 'success';
    },
    formatDate(date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD');
      }
      return null;
    },
    formatTime(date) {
      if (date) {
        return moment(date).format('HH:mm:ss');
      }
      return null;
    },
    async uploadTaskAppendixes(files) {
      for (const file of files) {
        let appendix = await FileHandler.handleFile(file);
        await this.$store.dispatch('uploadTaskAppendix', {
          appendix: appendix,
          taskId: this.task.id,
        });
      }
    },
    openFileInput() {
      document.getElementById('taskAppendixInput').click();
    },
    downloadAppendix(taskId, appendixId) {
      downloadTaskAppendix(taskId, appendixId).then((response) => {
        const element = document.createElement('a');
        element.setAttribute(
          'href',
          `data:${response.mimetype};base64,${response.file}`
        );
        element.setAttribute(
          'download',
          `${response.name}.${response.extension}`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      });
    },
    setupAppendixDelete(appendix) {
      this.appendixDeleteItem = appendix;
      this.appendixDeleteDialog = true;
    },
    removeTaskAppendix() {
      this.$store
        .dispatch('deleteTaskAppendix', {
          taskId: this.appendixDeleteItem.task,
          appendixId: this.appendixDeleteItem.id,
        })
        .then(() => {
          this.appendixDeleteItem = null;
          this.appendixDeleteDialog = false;
        });
    },
    createTaskCheck() {
      this.$store
        .dispatch('createTaskCheck', {
          taskId: this.task.id,
          check: {
            title: this.checkTitle,
          },
        })
        .then(() => {
          this.checkTitle = null;
          this.addCheckToggle = false;
        });
    },
    deleteTaskCheck(check) {
      this.$store.dispatch('deleteTaskCheck', {
        taskId: check.task,
        checkId: check.id,
      });
    },
    toggleTaskCheck(check) {
      this.$store.dispatch('updateTaskCheck', {
        taskId: this.task.id,
        checkId: check.id,
        body: {
          completed: check.completed,
        },
      });
    },
    updateTaskCheckTitle(check) {
      this.$store.dispatch('updateTaskCheck', {
        taskId: this.task.id,
        checkId: check.id,
        body: {
          title: check.title,
        },
      });
    },
    getPriorityIcon(priority) {
      return TaskHelper.getPriorityIcon(priority);
    },
    getPriorityColor(priority) {
      return TaskHelper.getPriorityColor(priority);
    },
    executeTask() {
      this.executing = true;
      executeTask(this.task.id)
        .then((response) => {
          switch (response.action) {
            case 'redirect':
              this.$router.push(response.url);
              break;
            case 'link':
              window.open(response.url, '__blank');
          }
          this.executing = false;
        })
        .catch((error) => {
          this.$store.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          this.executing = false;
        });
    },
    checkSelected(id) {
      return !!this.selectedSubtasks.find((t) => t.id === id);
    },
    onSubtaskSelect(task) {
      const taskIndex = this.selectedSubtasks.findIndex(
        (st) => st.id === task.id
      );
      if (taskIndex !== -1) {
        this.selectedSubtasks = this.selectedSubtasks.filter(
          (st) => st.id !== task.id
        );
      } else {
        this.selectedSubtasks.push(task);
      }
    },
    handleSubtasksSelection() {
      if (this.selectedSubtasks.length) {
        this.selectedSubtasks = [];
      } else {
        this.selectedSubtasks = this.task.children.slice();
      }
    },
    onSubtasksDeleteDialogClose(e) {
      this.subtasksDeleteDialog = e;
    },
    onSubtasksDelete() {
      this.$store.dispatch('reloadDetailTask', this.task.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.task-appendix-input {
  border: dashed 2px grey;
  border-radius: 5px;
  cursor: pointer;
  transition: 200ms linear;
  background-color: transparent !important;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;

  &:hover {
    border-color: var(--v-primary-base);

    .v-icon {
      color: var(--v-primary-base);
    }

    span {
      color: var(--v-primary-base);
    }
  }
}

.task-appendix {
  font-size: 10px;
  cursor: pointer;
  transition: 200ms;

  .ant-icon {
    opacity: 0;
  }

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .ant-icon {
      opacity: 1;
    }
  }
}

.task-check-item {
  height: 30px;
  font-size: 12px;

  .task-check-delete-btn {
    opacity: 0;
    transition: 200ms;
  }

  &:hover {
    .task-check-delete-btn {
      opacity: 1;
    }
  }

  &.task-check-item-add {
    color: var(--v-primary-base);
    cursor: pointer;

    &:hover {
      span {
        text-decoration: underline;
      }

      .v-icon {
        color: var(--v-primary-base);
      }
    }
  }
}
</style>
