<template>
  <div
    v-if="tasksTreeStatus === 'success'"
    ref="ant-gantt-container"
    class="d-flex flex-column flex-1 flex-scroll-width overflow-x-auto"
  >
    <tasks-gantt-chart
      ref="tasks-gantt"
      :can-link="true"
      :tasks="tasksTree"
      @scrollToToday="scrollWaterfallToToday"
    >
      <template #task-header-items>
        <tasks-gantt-default-item
          v-for="(task, index) in tasksTree"
          :key="`ant-gantt-header-${task.id}`"
          :index="index"
          :parents="[]"
          :show-labels="
            $refs['tasks-gantt'] ? $refs['tasks-gantt'].showLabels : false
          "
          :task="task"
          show-options
          @clickedTask="openTaskDetail"
          @redrawRelations="$refs['tasks-gantt'].drawRelations()"
          @toggleCollapse="toggleTaskCollapse"
        />
      </template>
    </tasks-gantt-chart>
  </div>
  <div v-else class="d-flex align-center justify-center flex-1">
    <ant-loading />
  </div>
</template>

<script>
import AntLoading from '../../AntLoading';
import TasksGanttChart from '../../Charts/TasksGanttChart.vue';
import { mapGetters } from 'vuex';
import TasksGanttDefaultItem from '@/components/Charts/TasksGanttDefaultItem';
import moment from 'moment';

export default {
  name: 'TasksGantt',
  components: { TasksGanttDefaultItem, TasksGanttChart, AntLoading },
  props: {
    tasks: {
      type: Array,
      required: false,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters(['tasksTree', 'tasksTreeStatus', 'selectedLicense']),
  },
  watch: {
    tasks(value) {
      this.$store.commit('tasks_tree_success', this.tasks);
    },
  },
  mounted() {
    if (this.tasks) {
      this.$store.commit('tasks_tree_success', this.tasks);
    } else {
      this.$store.dispatch('fetchTaskTree', {
        'filter[parent]': '',
        'filter[license]': this.selectedLicense.id,
      });
    }
  },
  methods: {
    scrollWaterfallToToday() {
      this.$nextTick(() => {
        this.$refs['ant-gantt-container'].scrollLeft =
          this.$refs['tasks-gantt'].getTodayOffset(moment()) -
          this.$refs['tasks-gantt'].dayWidth;
      });
    },
    toggleTaskCollapse({ task, parents }) {
      this.$store.commit('tasks_tree_toggle_collapse', {
        task: task,
        parents: parents,
      });
    },
    openTaskDetail(task) {
      this.$router.push({ name: 'tasks-detail', params: { taskId: task.id } });
    },
  },
};
</script>
