<template>
  <div class="d-flex">
    <div class="d-flex">
      <panel-resizable
        :collapsible="false"
        :default-width="400"
        :min-width="250"
        class="ant-glass-background full-height ant-border-right"
        side="left"
        style="border-radius: 0"
      >
        <v-list class="bg-transparent py-5 px-5" dense>
          <v-subheader>
            Tasks
            <v-spacer />
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  class="mx-1 ant-icon"
                  dense
                  @click="$refs['add-task-dialog'].displayed = true"
                  v-on="on"
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>Add task</span>
            </v-tooltip>
          </v-subheader>
          <add-task-dialog ref="add-task-dialog" />
          <v-list-item-group v-model="selectedView" color="primary">
            <v-list-item @click="$router.push({ name: 'tasks-list' })">
              <v-list-item-icon>
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>List</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'tasks-gantt' })">
              <v-list-item-icon>
                <v-icon class="mdi-rotate-270">mdi-chart-waterfall</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Gantt</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'tasks-board' })">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard-variant-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Board</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'tasks-calendar' })">
              <v-list-item-icon>
                <v-icon>mdi-calendar-blank-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Calendar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'tasks-projects' })">
              <v-list-item-icon>
                <v-icon>mdi-format-list-bulleted-square</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Projects</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </panel-resizable>
    </div>

    <div class="d-flex flex-column flex-1 flex-scroll-width">
      <transition mode="out-in" name="simple-fade">
        <div v-if="$route.name !== 'tasks-detail'" class="px-5 py-2 d-flex">
          <div
            class="ant-glass-background ant-border d-flex flex-1"
            style="height: 36px"
          >
            <div
              class="flex-1 pa-1 align-center d-flex overflow-x-auto overflow-y-hidden"
              style="width: 0"
            >
              <div
                v-for="(filter, index) in advancedTaskFilters"
                :key="`active-task-filter-${index}`"
                :class="{ 'ml-2': index > 0 }"
                class="d-flex"
              >
                <v-chip label small>
                  <v-icon class="mr-1" small
                    >{{ filter.selectedOption.icon }}
                  </v-icon>
                  {{ filter.selectedOption.text }}
                </v-chip>
                <v-chip class="mx-1" label small
                  >{{ filter.selectedOperator }}
                </v-chip>
                <v-chip
                  v-if="
                    filter.selectedValues.length > 0 &&
                    [
                      'assigned_to',
                      'project',
                      'label',
                      'priority',
                      'status',
                      'created_by',
                    ].includes(filter.selectedOption.value)
                  "
                  close
                  label
                  small
                  @click:close="removeActiveFilter(index)"
                >
                  <span
                    v-for="(item, index) in filter.selectedValues"
                    :key="`filter-label-${index}`"
                    :class="{
                      'pr-1 mr-1 ant-border-right':
                        filter.selectedValues.length - 1 !== index,
                    }"
                    >{{ item[filter.valueText] }}</span
                  >
                </v-chip>
                <v-chip
                  v-else
                  close
                  label
                  small
                  @click:close="removeActiveFilter(index)"
                  >{{ filter.selectedValues[0] }}
                </v-chip>
              </div>
              <div :class="{ 'ml-2': advancedTaskFilters.length > 0 }">
                <v-chip
                  v-if="addFilterSetup.selectedOption"
                  :close="!addFilterSetup.selectedOperator"
                  label
                  small
                  @click:close="resetAddFilter"
                >
                  <v-icon class="mr-1" small
                    >{{ addFilterSetup.selectedOption.icon }}
                  </v-icon>
                  {{ addFilterSetup.selectedOption.text }}
                </v-chip>
                <v-chip
                  v-if="addFilterSetup.selectedOperator"
                  class="mx-1"
                  close
                  label
                  small
                  @click:close="resetAddFilter"
                  >{{ addFilterSetup.selectedOperator }}
                </v-chip>
              </div>
              <v-menu :offset-x="true" style="z-index: 10" top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :style="{
                      opacity: !addFilterSetup.selectedOption ? '1' : '0',
                    }"
                    class="ml-1"
                    color="gainsboro"
                    icon
                    tile
                    v-bind="attrs"
                    x-small
                    v-on="on"
                  >
                    <v-icon dense>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in openFilterOptions"
                    :key="index"
                    class="fs-12"
                    @click="setFilterColumn(item)"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon small>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>{{ item.text }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                v-model="filterMenus.operator"
                :close-on-content-click="false"
                :offset-x="true"
                style="z-index: 10"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="gainsboro"
                    icon
                    style="opacity: 0; pointer-events: none; width: 0"
                    tile
                    v-bind="attrs"
                    x-small
                    v-on="on"
                  >
                    <v-icon dense>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-list v-if="addFilterSetup.selectedOption" dense>
                  <v-list-item
                    v-for="(item, index) in addFilterSetup.selectedOption
                      .operators"
                    :key="index"
                    class="fs-12"
                    @click="setFilterOperator(item)"
                  >
                    <v-list-item-content>{{ item }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                v-model="filterMenus.values"
                :close-on-content-click="false"
                :offset-x="true"
                style="z-index: 10"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="gainsboro"
                    icon
                    style="opacity: 0; pointer-events: none; width: 0"
                    tile
                    v-bind="attrs"
                    x-small
                    v-on="on"
                  >
                    <v-icon dense>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-card class="pa-2">
                  <v-combobox
                    v-if="
                      addFilterSetup.selectedOperator &&
                      [
                        'assigned_to',
                        'project',
                        'label',
                        'priority',
                        'status',
                        'created_by',
                      ].includes(addFilterSetup.selectedOption.value)
                    "
                    v-model="addFilterSetup.selectedValues"
                    :item-text="addFilterSetup.valueText"
                    :items="filterValueOptions"
                    :placeholder="addFilterSetup.selectedOption.value"
                    dense
                    multiple
                    @keydown.enter="setFilterValues"
                  ></v-combobox>
                </v-card>
                <v-date-picker
                  v-if="
                    addFilterSetup.selectedOperator &&
                    ['due', 'planned_start', 'planned_end'].includes(
                      addFilterSetup.selectedOption.value
                    )
                  "
                  @input="setDateValue"
                ></v-date-picker>
              </v-menu>
            </div>
            <v-divider vertical />
            <v-btn :ripple="false" class="px-2" icon tile @click="filterTasks">
              <v-icon v-if="tasksStatus !== 'loading'">mdi-magnify</v-icon>
              <v-icon
                v-if="tasksStatus === 'loading'"
                class="mdi-spin"
                color="primary"
                >mdi-loading
              </v-icon>
            </v-btn>
          </div>
          <template>
            <v-menu
              v-if="$route.name === 'tasks-list'"
              v-model="showMenu"
              attach="#menu-active"
              bottom
              close-on-click
              left
            >
              <template #activator="{ on, attrs }">
                <div
                  id="menu-active"
                  class="d-flex align-center ml-2 px-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    :class="{
                      'ant-icon': selectedTasks.length,
                    }"
                    dense
                    >mdi-dots-vertical
                  </v-icon>
                </div>
              </template>
              <v-list dense>
                <v-list-item
                  :disabled="!selectedTasks.length"
                  @click.stop="tasksDeleteDialog = true"
                >
                  <v-list-item-icon style="margin-right: 10px">
                    <v-icon dense> mdi-trash-can</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ $t('system.tasks.deleteSelectedTasks') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </div>
      </transition>
      <transition mode="out-in" name="simple-fade">
        <router-view />
      </transition>
    </div>
    <delete-tasks-dialog
      :is-shown="tasksDeleteDialog"
      :tasks="selectedTasks"
      @close-dialog="onDeleteDialogClose"
      @on-delete="$store.dispatch('fetchTasks')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddTaskDialog from '@/components/Tasks/AddTaskDialog';
import { getUserEmails } from '@/services/api/users.api';
import { getProjects } from '@/services/api/projects.api';
import { getLabels } from '@/services/api/labels.api';
import moment from 'moment';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import DeleteTasksDialog from '@/components/Tasks/DeleteTasksDialog.vue';

export default {
  name: 'Tasks',
  components: { DeleteTasksDialog, PanelResizable, AddTaskDialog },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit('clear_selected_tasks');
    this.showMenu = false;
    next();
  },
  data: () => {
    return {
      selectedView: null,
      filterMenus: {
        column: false,
        operator: false,
        values: false,
      },
      addFilterSetup: {
        selectedOption: null,
        selectedOperator: null,
        selectedValues: null,
        valueText: null,
      },
      filterValueOptions: [],
      filterOptions: [
        {
          icon: 'mdi-clipboard-check-outline',
          text: 'status',
          value: 'status',
          operators: ['=', '!='],
        },
        {
          icon: 'mdi-account-edit-outline',
          text: 'creator',
          value: 'created_by',
          operators: ['=', '!='],
        },
        {
          icon: 'mdi-account-outline',
          text: 'assignee',
          value: 'assigned_to',
          operators: ['=', '!='],
        },
        {
          icon: 'mdi-label-multiple-outline',
          text: 'label',
          value: 'label',
          operators: ['=', '!='],
        },
        {
          icon: 'mdi-inbox',
          text: 'project',
          value: 'project',
          operators: ['=', '!='],
        },
        {
          icon: 'mdi-signal-cellular-outline',
          text: 'priority',
          value: 'priority',
          operators: ['=', '!='],
        },
        {
          icon: 'mdi-calendar-outline',
          text: 'due',
          value: 'due',
          operators: ['=', '!=', '<', '>', '<=', '>='],
        },
        {
          icon: 'mdi-calendar-start',
          text: 'start date',
          value: 'planned_start',
          operators: ['=', '!=', '<', '>', '<=', '>='],
        },
        {
          icon: 'mdi-calendar-end',
          text: 'end date',
          value: 'planned_end',
          operators: ['=', '!=', '<', '>', '<=', '>='],
        },
      ],
      showMenu: false,
      tasksDeleteDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'tasksStatus',
      'selectedLicense',
      'advancedTaskFilters',
      'projects',
      'selectedTasks',
      'authenticatedUser',
    ]),

    openFilterOptions() {
      return this.filterOptions.filter(
        (item) =>
          this.advancedTaskFilters.findIndex(
            (item2) => item2.selectedOption.value === item.value
          ) === -1
      );
    },
  },
  watch: {
    filterMenus: {
      deep: true,
      handler(value) {
        if (!value.column && !value.operator && !value.values) {
          this.resetAddFilter();
        }
      },
    },
    $route: {
      handler(value) {
        switch (value.name) {
          case 'tasks-list':
            this.selectedView = 0;
            break;
          case 'tasks-gantt':
            this.selectedView = 1;
            break;
          case 'tasks-board':
            this.selectedView = 2;
            break;
          case 'tasks-calendar':
            this.selectedView = 3;
            break;
          case 'tasks-projects':
            this.selectedView = 4;
            break;
          case 'tasks':
            this.$router.push({ name: 'tasks-list' });
            break;
          default:
            this.selectedView = null;
            break;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    if (this.project) {
      let filter = {
        selectedOption: {
          icon: 'mdi-inbox',
          text: 'project',
          value: 'project',
          operators: ['=', '!='],
        },
        selectedOperator: '=',
        selectedValues: [this.project],
        valueText: 'name',
      };
      this.$store.commit('tasks_add_advanced_filter', filter);
    }

    this.$store.commit('tasks_add_advanced_filter', {
      selectedOption: {
        icon: 'mdi-clipboard-check-outline',
        text: 'status',
        value: 'status',
        operators: ['=', '!='],
      },
      selectedOperator: '=',
      selectedValues: [{ title: 'open' }],
      valueText: 'title',
    });

    this.$store.commit('tasks_add_advanced_filter', {
      selectedOption: {
        icon: 'mdi-account-outline',
        text: 'assignee',
        value: 'assigned_to',
        operators: ['=', '!='],
      },
      selectedOperator: '=',
      selectedValues: [this.authenticatedUser],
      valueText: 'email',
    });
  },
  mounted() {
    this.$store.dispatch('fetchTasks');
  },
  destroyed() {
    this.$store.commit('tasks_reset_advanced_filters');
  },
  methods: {
    filterTasks() {
      if (['tasks-gantt', 'tasks-projects'].includes(this.$route.name)) {
        this.$store.dispatch('fetchTaskTree', {
          'filter[parent]': '',
          'filter[license]': this.selectedLicense.id,
        });
      } else {
        this.$store.dispatch('fetchTasks');
      }
    },
    removeActiveFilter(index) {
      this.$store.commit('tasks_remove_advanced_filter', index);
      this.filterTasks();
    },
    setFilterColumn(option) {
      this.addFilterSetup.selectedOption = option;
      this.filterMenus.operator = true;
      this.filterMenus.column = false;
    },
    async setFilterOperator(operator) {
      this.addFilterSetup.selectedOperator = operator;

      switch (this.addFilterSetup.selectedOption.value) {
        case 'created_by':
          this.addFilterSetup.valueText = 'email';
          this.filterValueOptions = await getUserEmails();
          break;
        case 'assigned_to':
          this.addFilterSetup.valueText = 'email';
          this.filterValueOptions = await getUserEmails();
          break;
        case 'project':
          this.addFilterSetup.valueText = 'name';
          this.filterValueOptions = await getProjects({
            'filter[license]': this.selectedLicense.id,
          });
          break;
        case 'label':
          this.addFilterSetup.valueText = 'title';
          this.filterValueOptions = await getLabels({
            'filter[license]': this.selectedLicense.id,
          });
          break;
        case 'priority':
          this.addFilterSetup.valueText = 'title';
          this.filterValueOptions = [
            { title: 'low' },
            { title: 'normal' },
            { title: 'high' },
            { title: 'urgent' },
          ];
          break;
        case 'status':
          this.addFilterSetup.valueText = 'title';
          this.filterValueOptions = [{ title: 'open' }, { title: 'closed' }];
          break;
      }

      this.filterMenus.values = true;
      this.$nextTick(() => {
        this.filterMenus.operator = false;
      });
    },
    setFilterValues() {
      this.$store.commit('tasks_add_advanced_filter', this.addFilterSetup);
      this.filterMenus.values = false;
      this.resetAddFilter();
    },
    setDateValue(lastDate) {
      this.addFilterSetup.selectedValues = [];
      this.addFilterSetup.selectedValues.push(
        moment(date).format('YYYY-MM-DD HH:mm:ss')
      );
      this.$store.commit('tasks_add_advanced_filter', this.addFilterSetup);
      this.filterMenus.values = false;
      this.resetAddFilter();
    },
    resetAddFilter() {
      this.addFilterSetup = {
        selectedOption: null,
        selectedOperator: null,
        selectedValues: null,
        valueText: null,
      };
    },
    onDeleteDialogClose(state) {
      this.tasksDeleteDialog = state;
    },
  },
};
</script>

<style lang="scss" scoped>
.task-filter-option {
  transition: 200ms;
  cursor: pointer;

  &:hover {
    background-color: #6b6b6b;
  }
}
</style>
