import { render, staticRenderFns } from "./TasksDetail.vue?vue&type=template&id=274f7e09&scoped=true"
import script from "./TasksDetail.vue?vue&type=script&lang=js"
export * from "./TasksDetail.vue?vue&type=script&lang=js"
import style0 from "./TasksDetail.vue?vue&type=style&index=0&id=274f7e09&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274f7e09",
  null
  
)

export default component.exports